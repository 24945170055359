import React, { useCallback } from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEOData } from 'types';
import { EarlyAccessLayout } from 'components/EarlyAccess';

export interface EarlyAccessFormData {
  firstName: string;
  email: string;
  acceptEmails: boolean;
  country: string;
  motivation: string;
}

const EarlyAccess: React.FC<PageProps<SEOData>> = ({ location, data }) => {
  const handleSubmit = useCallback(async () => {
    // register to mail list
  }, [location]);

  return (
    <EarlyAccessLayout
      handleSubmit={handleSubmit}
      seoData={{
        url: `${data.SEO.siteMetadata.siteUrl}${location.pathname}`,
        title: data.SEO.siteMetadata.defaultTitle,
        description: data.SEO.siteMetadata.defaultDescription || '',
      }}
    />
  );
};

export const eaQuery = graphql`
  query {
    SEO: site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        twitterUsername
      }
    }
  }
`;

export default EarlyAccess;

import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';

export const HeaderLink = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
`;

export const HeaderContainer = styled(Container)`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React, { useCallback, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useForm } from 'react-hook-form';
import { EarlyAccessFormData } from 'pages/early-access';
import {
  BODY1,
  BODY2,
  BODY3,
  Button,
  Checkbox,
  GridContainer,
  GridItem,
  H2,
  SEO,
  SeoProps,
} from '@tuunetech/tuune-components';
import { ErrorMessage, Input, Select, Link } from 'components/shared';
import { Header } from 'components/EarlyAccess';
import {
  MainContainer,
  Form,
  DescribeItem,
  SignupButtonWrapper,
  StyledBackgroundImage,
} from './styles';
import { LANDING_SIGN_UP_SUBMIT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';

countries.registerLocale(enLocale);
const countriesObject = countries.getNames('en', { select: 'official' });

const PRE_SELECTED = [
  {
    value: 'GB',
    label: countries.getName('GB', 'en', { select: 'official' }),
  },
  {
    value: 'US',
    label: countries.getName('US', 'en', { select: 'official' }),
  },
];

const filteredCountries = Object.keys(countriesObject)
  .filter(key => key !== PRE_SELECTED[0].value && key !== PRE_SELECTED[1].value)
  .map(countryKey => ({
    value: countryKey,
    label: countriesObject[countryKey],
  }));

const COUNTRIES = PRE_SELECTED.concat(filteredCountries);

const DESCRIBE_OPTIONS: {
  value: string;
  label: string;
  subTitle?: string;
  textLabel: string;
}[] = [
  // {
  //   value: 'I’m looking for contraception',
  //   textLabel: 'I’m looking for contraception',
  //   label: `I’m looking for contraception`,
  //   subTitle: undefined,
  // },
  // {
  //   value: 'I think I have symptoms and I’m not sure what the cause is',
  //   textLabel: 'I think I have symptoms and I’m not sure what the cause is',
  //   label: `I think I have symptoms and I’m not sure what the cause is`,
  //   subTitle: undefined,
  // },
  // {
  //   value:
  //     'I’ve been diagnosed with hormonal symptoms and I’m open to contraception (PCOS, endometriosis, hormonal acne, migraines, insomnia...)',
  //   textLabel:
  //     'I’ve been diagnosed with hormonal symptoms and I’m open to contraception (PCOS, endometriosis, hormonal acne, migraines, insomnia...)',
  //   label: `I’ve been diagnosed with hormonal symptoms and I’m open to
  //         contraception`,
  //   subTitle: `(PCOS, endometriosis, hormonal acne, migraines, insomnia...)`,
  // },
  // {
  //   value:
  //     'I’ve been diagnosed with hormonal symptoms, but I’m not looking for contraception (PCOS, endometriosis, hormonal acne, migraines, insomnia...)',
  //   textLabel:
  //     'I’ve been diagnosed with hormonal symptoms, but I’m not looking for contraception (PCOS, endometriosis, hormonal acne, migraines, insomnia...)',
  //   label: `I’ve been diagnosed with hormonal symptoms, but I’m not looking for contraception`,
  //   subTitle: '(PCOS, endometriosis, hormonal acne, migraines, insomnia...)',
  // },
  {
    value: 'I want to switch to a birth control option that’s personal to me',
    textLabel:
      'I want to switch to a birth control option that’s personal to me',
    label: `I want to switch to a birth control option that’s personal to me`,
  },
  {
    value: 'I want to start a birth control that’s personalized to me',
    textLabel: 'I want to start a birth control that’s personalized to me',
    label: `I want to start a birth control that’s personalized to me`,
    subTitle: `I haven’t used birth control yet`,
  },
  {
    value:
      'I want to get off hormonal birth control and am looking for a non hormonal solution',
    textLabel:
      'I want to get off hormonal birth control and am looking for a non hormonal solution',
    label: `I want to get off hormonal birth control and am looking for a non hormonal solution`,
  },
  {
    value:
      'I’m not interested in birth control, I just want to better understand my hormones',
    textLabel:
      'I’m not interested in birth control, I just want to better understand my hormones',
    label: `I’m not interested in birth control, I just want to better understand my hormones`,
  },
];

const getSelectRules = (errorMessage: string) => ({
  validate: {
    required: (value: string) => (value ? true : errorMessage),
  },
});

interface EarlyAccessLayoutProps {
  handleSubmit: (data: EarlyAccessFormData) => void;
  seoData?: SeoProps;
}

export const EarlyAccessLayout: React.FC<EarlyAccessLayoutProps> = ({
  handleSubmit,
  seoData,
}) => {
  const {
    register,
    handleSubmit: hookHandleSubmit,
    watch,
    errors,
    setValue,
    clearErrors,
  } = useForm();
  const analytics = useAnalytics();
  const watchCountry = watch('country', undefined);
  const watchMotivation = watch('motivation', undefined);

  const describeOptions = useMemo(() => {
    return DESCRIBE_OPTIONS.map(({ value, label, subTitle, textLabel }) => ({
      value,
      label: (
        <DescribeItem>
          <BODY2>{label}</BODY2>
          {subTitle && <BODY2>{subTitle}</BODY2>}
        </DescribeItem>
      ),
      textLabel,
    }));
  }, []);

  const submit = useCallback(
    (data: EarlyAccessFormData) => {
      handleSubmit({
        ...data,
        // convert country from key to name
        country: countries.getName(data.country, 'en'),
      });
      analytics.track(LANDING_SIGN_UP_SUBMIT, {
        country: data.country,
        motivation: data.motivation,
      });
    },
    [analytics],
  );

  const bgData = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "form_bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <SEO {...seoData} />
      <Header />
      <StyledBackgroundImage fluid={bgData.desktop?.childImageSharp.fluid}>
        <MainContainer>
          <GridContainer justifyContent="center">
            <GridItem md={6} lg={5} xl={3}>
              <H2 align="center">Get early access</H2>
              <BODY1 align="center">
                Are you ready to be part of the revolution? Be one of the first
                to find out when we launch!
              </BODY1>
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} md={8} lg={6} xl={4}>
              <Form onSubmit={hookHandleSubmit(submit)}>
                <Input
                  name="firstName"
                  placeholder="First name"
                  label="What’s your name?"
                  ref={register({ required: 'First name is required.' })}
                  error={errors.firstName?.message}
                />
                <Input
                  name="email"
                  placeholder="user@tuune.com"
                  label="What’s your email?"
                  type="email"
                  ref={register({
                    required: 'Email is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Please use a valid email.',
                    },
                  })}
                  error={errors.email?.message}
                />
                <Checkbox
                  name="acceptEmails"
                  color="primary"
                  label="I would like to receive Tuune’s latest updates by email."
                  inputRef={register({
                    required:
                      'Please review if you agree to received updates from Tuune',
                  })}
                />
                {errors.acceptEmails?.message && (
                  <ErrorMessage message={errors.acceptEmails?.message} />
                )}
                <Select
                  name="country"
                  options={COUNTRIES}
                  onChange={country => {
                    setValue('country', country.value);
                    clearErrors('country');
                  }}
                  value={watchCountry}
                  label="Where do you live?"
                  placeholder="Select your country"
                  ref={register(getSelectRules('Please select your country.'))}
                  error={errors.country?.message}
                  searchable
                />
                <Select
                  name="motivation"
                  options={describeOptions}
                  onChange={motivation => {
                    setValue('motivation', motivation.value);
                    clearErrors('motivation');
                  }}
                  value={watchMotivation}
                  label="Select the sentence that describes you the best:"
                  placeholder="Select a sentence"
                  ref={register()}
                  error={errors.motivation?.message}
                />
                <SignupButtonWrapper>
                  <Button type="submit">Sign up</Button>
                  <BODY3>
                    By clicking Sign Up I consent to Tuune’s{' '}
                    <Link to="privacy-policy">Privacy Policy</Link>
                  </BODY3>
                </SignupButtonWrapper>
              </Form>
            </GridItem>
          </GridContainer>
        </MainContainer>
      </StyledBackgroundImage>
    </>
  );
};

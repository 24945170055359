import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { BODY2, BODY3, Container, H2 } from '@tuunetech/tuune-components';

export const MainContainer = styled(Container)`
  height: calc(100vh - 60px);
  overflow: auto;

  ${H2} {
    padding-top: 43px;
    padding-bottom: 14px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const DescribeItem = styled.div`
  ${BODY2} {
    margin: 0;
    text-align: start;
  }

  ${BODY2} + ${BODY2} {
    font-size: 12px;
    font-style: italic;
    margin-top: 7px;
  }
`;

export const SignupButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 20px;

  ${BODY3}, .MuiLink-root {
    font-size: 8px;
    line-height: 12px;
    margin-top: 10px;
  }
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  background-color: ${({ theme }) => theme.palette.background.light};
  background-position-y: center;
  background-position-x: 80%;
  background-repeat: no-repeat;
  background-size: 130%;
`;

import React from 'react';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Link, Logo } from 'components/shared';
import { HeaderContainer, HeaderLink } from './styles';

export const Header: React.FC = () => {
  return (
    <HeaderContainer maxWidth={false}>
      <Container fixed>
        <GridContainer alignItems="center" justifyContent="space-between">
          <GridItem>
            <HeaderLink>
              <Link to="/" underline="none">
                back
              </Link>
            </HeaderLink>
          </GridItem>
          <GridItem>
            <Logo />
          </GridItem>
          <GridItem />
        </GridContainer>
      </Container>
    </HeaderContainer>
  );
};
